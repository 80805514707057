import styled from 'styled-components';
import Link from 'gatsby-theme-wordpress/src/components/highlighted-pages/link';

export default styled(Link)`
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  background-color: #88b526;
  border-radius: 3px;
  border: 0px solid #88b526;
  padding: 8px 14px 7px 14px;
  &:hover {
    color: #ededed;
  }
`;
