import styled from 'styled-components';
import Content from 'gatsby-theme-wordpress/src/components/highlighted-pages/content';

export default styled(Content)`
  padding: 30px;
  a {
    text-decoration: none;
  }
  h2 {
    color: #003366;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }
  /* text-align: left; */
`;
