import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useLocation } from 'gatsby-theme-wordpress/src/context/location';
import { useI18n } from 'gatsby-theme-wordpress/src/context/language';
import FacebookF from './FacebookF';
import LinkedinIn from './LinkedinIn';
import Whatsapp from './Whatsapp';
import Twitter from './Twitter';
import Anchor from './Link';

const Share = ({ className, pageTitle }) => {
  const location = useLocation();
  const url = location.href;
  const [copyState, setCopyState] = useState(false);
  const onCopyClick = useCallback(() => {
    navigator.clipboard.writeText(url).then(() => {
      setCopyState(true);
    });
  }, [url]);
  const i18n = useI18n();

  return (
    <div className={className}>
      <span>{i18n?.share}</span>
      <Link
        to={`https://api.whatsapp.com/send?phone=&text=${pageTitle} ${url}`}
        title="Whatsapp"
      >
        <Whatsapp />
      </Link>
      <Link
        to={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
        title="LinkedIn"
      >
        <LinkedinIn />
      </Link>
      <Link
        to={`http://www.facebook.com/sharer.php?u=${url}&t=${pageTitle}`}
        title="Facebook"
      >
        <FacebookF />
      </Link>
      <Link
        to={`https://x.com/intent/tweet?text=${pageTitle}&url=${url}`}
        title="X/Twiter"
      >
        <Twitter />
      </Link>
      <button type="button" onClick={onCopyClick} title={i18n?.shareCopy}>
        <Anchor />
        {copyState ? i18n?.shareCopied : i18n?.shareCopy}
      </button>
    </div>
  );
};

export default styled(Share)`
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  span {
    margin-right: 0.5rem;
  }

  a {
    display: block;
    background-color: #88b526;
    border-radius: 3px;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: white;
    width: 1.7rem;
    height: 1.7rem;
  }

  button {
    all: unset;
    cursor: pointer;
    height: 2.5rem;
    padding: 0 1rem 0 0.5rem;
    border: 1px solid #88b526;
    border-radius: 3px;
    color: #88b526;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    svg {
      color: #88b526;
    }
  }

  @media only screen and (min-width: 768px) {
    justify-content: start;
  }
`;
