import styled from 'styled-components';

const Content = styled.div`
  padding: 30px;
  a {
    text-decoration: none;
  }
`;

export default Content;
