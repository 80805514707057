import React from 'react';
import styled from 'styled-components';
import ContactForm from './contact-form';

const arrowSvg = `<svg
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path fill="rgb(102,102,102)" d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z" />
  </svg>`
  .replace(/\(/g, '%28')
  .replace(/\)/g, '%29');

export default styled(({ className, children, content }) => {
  if (children) {
    return <div className={className}>{children}</div>;
  }
  return <div className={className} dangerouslySetInnerHTML={{ __html: content }} />;
})`
  h2 {
    margin: 0 0 20px;
    font-size: 34px;
    line-height: 44px;
  }

  h3 {
    margin: 0 0 10px;
    font-size: 24px;
    line-height: 34px;
  }

  a {
    color: #88b526;
    &:hover {
      text-decoration: none;
    }
  }

  ol, ul {
    padding: 0;
    margin: 0 0 10px 20px;
  }

  ul {
    list-style: disc;
  }

  figure.wp-block-table {
    padding: 0;
    margin: 0;
  }

  table {
    border-bottom: 1px solid rgba(170, 170, 170, 0.25);
    width: 100%;
    margin: 0 0 10px;
    border-collapse: collapse;
    border-spacing: 0;
  }

  td, th {
    border-top: 1px solid rgba(170, 170, 170, 0.25);
    padding: 5px 10px 5px 5px;
  }

  .wp-block-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .wp-block-file {
    margin-bottom: 10px;
    .wp-block-file__button {
      display: none;
    }
    a {
      display: inline-block;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.01) 100%);
      background-repeat: repeat-x;
      color: #666;
      border: 1px solid #EBEBEB;
      background-color: #EBEBEB;
      font-size: 14px;
      line-height: normal;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 20px;
      &:after {
        vertical-align: middle;
        background-image: url('data:image/svg+xml, ${arrowSvg}');
        content: '';
        display: inline-block;
        background-size: cover;
        width: 14px;
        height: 16px;
        margin-right: 20px;
        margin-left: 14px;
      }
      &:hover {
        color: #5e5e5e;
        border-color: #dcdcdc;
        background-color: #dcdcdc;
      }
    }
  }

  .alignright {
    @media screen and (min-width: 778px) {
      float: right;
      margin: 0 0 10px 30px;
    }
  }

  .has-text-align-center {
    text-align: center;
  }

  .has-media-on-the-right {
    .wp-block-media-text__media {
      float: right;
    }
  }

  .wp-block-spacer {
    clear: both;
  }

  .wp-block-columns {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    @media screen and (min-width: 778px) {
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
  }

  + ${ContactForm} {
    border-top: 1px solid #EBEBEB;
    padding-top: 15px;
  }
`;
