import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import ChevronRight from 'gatsby-theme-wordpress/src/components/icons/chevron-right';
import { useLanguage } from 'gatsby-theme-wordpress/src/context/language';

const activeClassName = 'page-menu-active';

// MenuItems fragment comes from the main menu
export const pageQuery = graphql`
  fragment PageMenu on wordpress__PAGE {
    custom {
      menu {
        ...MenuItems
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
`;

const LinkIcon = styled.div`
  background-color: rgba(136, 181 ,38 ,0.15);
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ChevronRight} {
    width: 6px;
  }
`;

const StyledLink = styled(Link)`
  color: #88b526;
  text-decoration: none;
  padding-left: 8px;
  position: relative;
  display: inline-block;

  &.${activeClassName} {
    + ${LinkIcon} {
     ${ChevronRight} {
        color: rgba(136, 181, 38, 0.999);
      }
    }
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;

  li {
    &:not(:first-child) {
      border-top: 1px solid rgba(136,181,38,0.15);
      margin: 9px 0 0;
      padding: 10px 0 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 778px) {
      justify-content: left;
    }

    ${StyledLink} {
      order: 2;
    }

    ${LinkIcon} {
      order: 1;
    }

    ${ChevronRight} {
      color: rgba(136, 181, 38, 0.5);
    }
    &:hover {
      ${ChevronRight} {
        color: rgba(136, 181, 38, 0.999);
      }
    }
  }
`;

const PageMenu = ({ className, custom }) => {
  const language = useLanguage();

  if (!custom?.menu?.items) {
    return null;
  }

  return (
    <div className={className}>
      {custom.menu.items.map((item) => (
        <div key={item.id}>
          <Title>{item.title}</Title>
          <List>
            {(item.children || []).sort((a, b) => a.order > b.order).map((child) => (
              <li key={child.id}>
                <StyledLink
                  to={child.url}
                  title={child.title}
                  target={child.target}
                  partiallyActive={child.url !== `/${language}/`}
                  activeClassName={activeClassName}
                >
                  {child.title}
                </StyledLink>
                <LinkIcon>
                  <ChevronRight />
                </LinkIcon>
              </li>
            ))}
          </List>
        </div>
      ))}
    </div>
  );
};

export default styled(PageMenu)`
  background: #003366;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 40px;
  padding-bottom: 40px;

  color: #88b526;
  font-size: 14px;
`;
