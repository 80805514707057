import styled from 'styled-components';
import Page from 'gatsby-theme-wordpress/src/components/highlighted-pages/page';

export default styled(Page)`
  border-top-width: 30px;
  border-top-color: #f4e200;
  border-top-style: solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #bed179;
`;
