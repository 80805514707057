import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import HighlightedPages from 'gatsby-theme-wordpress/src/components/highlighted-pages';

import Layout from '../../components/layout';
import ContactForm from '../../components/contact-form';
import PageMenu from '../../components/page-menu';
import Content from '../../components/content';
import Wrap from '../../components/wrap';
import Share from '../components/share/share';

// remember Vacancy uses this component, but not this query
export const pageQuery = graphql`
  query Page($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          caption
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }

      fancyheader: featured_media {
        ...Fancyheader
      }
      ...Breadcrumbs
      title
      content
      acf {
        form: contact_form
      }
      ...HighlightedPages
      ...PageMenu
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${PageMenu} {
    grid-row: 2;
    + ${Content} {
      margin-bottom: 60px;
      @media screen and (min-width: 990px) {
        margin-bottom: 0;
      }
    }
  }

  ${Content} {
    grid-row: 1;
  }

  @media screen and (min-width: 990px) {
    grid-column-gap: 50px;
    grid-template-columns: calc(350px - 25px) calc(100% - 350px - 25px);

    ${Content} {
      grid-column: 1 / 3;
    }

    ${PageMenu} {
      grid-row: 1;
      + ${Content} {
        grid-column: 2 / 3;
      }
    }
  }
`;

export const PageTemplate = ({ data, withShare = false }) => {
  const { page } = data;
  return (
    <Layout page={page}>
      <Wrap>
        <Grid>
          <PageMenu {...page} />
          <Content
            content={page.content}
          />
        </Grid>
      </Wrap>
      <Wrap noMobilePadding>
        <HighlightedPages {...page} />
      </Wrap>

      {withShare && (
        <Wrap>
          <Share pageTitle={page.yoast_title} />
        </Wrap>
      )}

      {page.acf?.form && (
        <Wrap>
          <ContactForm htmlString={page.acf.form} />
        </Wrap>
      )}
    </Layout>
  );
};

export default PageTemplate;
